<template>
    <div class="roleTab clearfix">
      <list-search @onSearch="onSearch" @clearSearch="clearSearch" :childData="childData"></list-search>
      <global-table ref="libraryData" :tableField="tableField" :tableData="tableData"
    ></global-table>
    </div>
</template>

<script>
import { requestForm } from '@/assets/js/http.js'
import Component from '@/assets/js/components.js'
import { SearchList, SearchData, tableField, tableData } from './AddInDeliveryBody.js'
export default {
  components: Component.components,
  name: 'AddInDeliveryBody',
  props: ['tabData', 'dataBox'],
  data () {
    return {
      tableField: tableField,
      tableData: tableData,
      selectTabData: [],
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    // this.myBidData()
  },
  mounted () {

  },
  methods: {
    // 获取添加物料数据列表
    myBidData () {
      const obj = {
        livraisonCode: this.childData.searchData.bh,
        supplierNum: this.dataBox.ruleSupplierNumber
        // providerCode: '0000013221'
      }
      requestForm('/api/order/supplier/querygetDemandDataByLivra', 'POST', obj).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data
        }
      })
    },
    // 查询
    onSearch (data) {
      this.myBidData()
    },
    // 清空
    clearSearch (data) {
      this.myBidData()
    }
  },
  watch: {
  }
}
</script>
<style scoped>
  .tableNav{
    border: none !important;
  }
  .tableList{
    padding: 0 20px !important;
  }
  .roleTab .tableNav :deep(.searchList .col){
    min-width: 320px;
  }
  .roleTab .tableNav :deep(.searchList .col .title){
    min-width: 42px;
  }
</style>
