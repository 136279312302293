export var SearchList = [
  { labe: '送货单号', code: 'bh', type: 'input' }
]
export var SearchData = {
  bh: ''
}

export var tableField = [
  { type: 'input', selectType: 'selection', width: '' },
  { label: '送货单号', code: 'livraisonCode', type: 'input', width: '60%' },
  { label: '送货单行号', code: 'livraisonRow', type: 'input', width: '50%' },
  { label: '物料编码', code: 'materialCode', type: 'input', width: '60%' },
  { label: '物料描述', code: 'materialName', type: 'input', width: '80%' },
  { label: '数量/单位', code: 'number', type: 'input', width: '40%' }
]

export var tableData = []
